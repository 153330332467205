import {CONSTANTS} from "../../constants/constants";
import {setIsAuth} from "../slices/userSlice";
import {resetAuthState} from "../slices/authSlice";
import {createAsyncThunk} from '@reduxjs/toolkit';
import {localStorageService} from "../../services/localStorageService";

export const logoutUserThunk = createAsyncThunk(
  'auth/logout',
  async (_, {dispatch}) => {
    try {
      localStorageService.remove(CONSTANTS?.VALUE?.ACCESS_TOKEN);
      dispatch(setIsAuth(false));
      dispatch(resetAuthState());
    } catch (error) {
      throw Error(error);
    }
  }
);

export const authorizeUserThunk = createAsyncThunk(
  'auth/authorize',
  async ({accessToken}, {dispatch}) => {
    try {
      localStorageService.set(CONSTANTS?.VALUE?.ACCESS_TOKEN, accessToken);
      dispatch(setIsAuth(true));
      dispatch(resetAuthState());
    } catch (error) {
      throw Error(error);
    }
  }
);