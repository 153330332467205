export const CONFIG_CONSTANTS = {

  APP_NAME: "RentVip",

  COUNTRY_DEFAULT: 'AE',

  CURRENCY: {
    AVAILABLE: {
      EUR: {
        TYPE: 'EUR',
        SYMBOL: '€',
        countries: [
          "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI",
          "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU",
          "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE",
        ]
      },
      QAR: { TYPE: 'QAR', SYMBOL: '﷼', countries: ["QA"] },
      SAR: { TYPE: 'SAR', SYMBOL: '﷼', countries: ["SA"] },
      AED: { TYPE: 'AED', SYMBOL: 'د.إ', countries: ["AE"] },
      USD: { TYPE: 'USD', SYMBOL: '$', countries: [] },
    },
    DEFAULT: { TYPE: 'USD', SYMBOL: '$' }
  },

  // all
  // en ar hi es ru ua zh ja ko
  LANGUAGES: {
    AVAILABLE: {
      EN: {
        TYPE: 'EN',
        VALUE: 'en',
        NATIVE_NAME: 'English',
        countries: ["AE", "US", "GB", "CA", "AU", "NZ"],
        locale: "enUS",
        isRTL: false,
      },
      AR: {
        TYPE: 'AR',
        VALUE: 'ar',
        NATIVE_NAME: 'العربية',
        countries: ["SA", "QA", "EG", "MA"],
        locale: "ar",
        isRTL: true,
      },
      HI: {
        TYPE: 'HI',
        VALUE: 'hi',
        NATIVE_NAME: 'हिन्दी',
        countries: ["IN"],
        locale: "hi",
        isRTL: false,
      },
      ES: {
        TYPE: 'ES',
        VALUE: 'es',
        NATIVE_NAME: 'Español',
        countries: ["ES", "MX", "AR", "CO", "CL"],
        locale: "es",
        isRTL: false,
      },
      RU: {
        TYPE: 'RU',
        VALUE: 'ru',
        NATIVE_NAME: 'Русский',
        countries: ["AM", "AZ", "BY", "KZ", "KG", "MD", "RU", "TJ", "TM", "UZ"],
        locale: "ru",
        isRTL: false,
      },
      UA: {
        TYPE: 'UA',
        VALUE: 'ua',
        NATIVE_NAME: 'Українська',
        countries: ["UA"],
        locale: "uk",
        isRTL: false,
      },
      ZH: {
        TYPE: 'ZH',
        VALUE: 'zh',
        NATIVE_NAME: '简体中文',
        countries: ["CN", "SG"],
        locale: "zhCN",
        isRTL: false,
      },
      JA: {
        TYPE: 'JA',
        VALUE: 'ja',
        NATIVE_NAME: '日本語',
        countries: ["JP"],
        locale: "ja",
        isRTL: false,
      },
      KO: {
        TYPE: 'KO',
        VALUE: 'ko',
        NATIVE_NAME: '한국어',
        countries: ["KR"],
        locale: "ko",
        isRTL: false,
      }
    },
    DEFAULT: {
      TYPE: 'EN',
      VALUE: 'en',
      NATIVE_NAME: 'English',
      locale: "enUS",
      isRTL: false,
    }
  },

  //DATE
  DATE: {
    FORMAT: {
      URL: 'DD-MM-YYYY',
    },
  },

  CONNECTION_FOOTER: {
    TEL: '+971-56-227-5555',
    EMAIL: 'agent@rentvip.com',
  },

  //LOGIC
  LOGIC_SETTINGS: {
    SEARCH: {
      GUESTS: {
        MAX_GUESTS: 10,
        MAX_INFANTS: 5,
      },
      MAX_ROOM: 10,
    },
    BOOKING: {
      GUESTS: {
        MAX_ADULTS: 10,
        MAX_INFANTS: 5,
      },
      CRYPTO_COINS: [ "USDTTRC20", "BTC", "ETH", "LTC", "DOGECOIN", "TRX", "SOL", "BNBBSC", "TON", "XRP" ],
    }
  },

  //UI
  UI_SETTINGS: {
    HOME: {
      LIST_QUANTITY: {
        PROPERTY: {
          DESKTOP: 6,
          MOBILE: 6,
        },
        CAR: {
          DESKTOP: 6,
          MOBILE: 6,
        },
        TOUR: {
          DESKTOP: 3,
          MOBILE: 3,
        },
      }
    },
    SEARCH: {
      LIST_QUANTITY: {
        PROPERTY: {
          DESKTOP: 20,
          MOBILE: 15,
        },
        CAR: {
          DESKTOP: 20,
          MOBILE: 15,
        },
        TOUR: {
          DESKTOP: 20,
          MOBILE: 15,
        },
      }
    },
    DETAILS: {
      GALLERY: {
        MAIN: {},
        FULL_SCREEN: {
          ZOOM: {
            DESKTOP: { MAX_RATIO: 3 },
            MOBILE: { MAX_RATIO: 3 },
          },
          SLIDES_PER_VIEW: {
            DESKTOP: 10,
            MOBILE: 5,
          },
          SPACE_BETWEEN: {
            DESKTOP: 10,
            MOBILE: 0,
          },
        }
      }
    }
  },

  STAYS_FILTER_PRICES: {
    USD: [
      {name: "USD 0 - 150", value: {minPrice: 1, maxPrice: 150}, id: 1},
      {name: "USD 150 - 300", value: {minPrice: 150, maxPrice: 300}, id: 2},
      {name: "USD 300 - 500", value: {minPrice: 300, maxPrice: 500}, id: 3},
      {name: "USD 500 - 1500", value: {minPrice: 500, maxPrice: 1500}, id: 4},
      {name: "USD 1500 and above", value: {minPrice: 1500, maxPrice: 999999}, id: 5},
    ],
    EUR: [
      {name: "EUR 0 - 150", value: {minPrice: 1, maxPrice: 150}, id: 1},
      {name: "EUR 150 - 300", value: {minPrice: 150, maxPrice: 300}, id: 2},
      {name: "EUR 300 - 500", value: {minPrice: 300, maxPrice: 500}, id: 3},
      {name: "EUR 500 - 1500", value: {minPrice: 500, maxPrice: 1500}, id: 4},
      {name: "EUR 1500 and above", value: {minPrice: 1500, maxPrice: 999999}, id: 5},
    ],
    AED: [
      {name: "AED 0 - 500", value: {minPrice: 1, maxPrice: 500}, id: 1},
      {name: "AED 500 - 1000", value: {minPrice: 500, maxPrice: 1000}, id: 2},
      {name: "AED 1000 - 2000", value: {minPrice: 1000, maxPrice: 2000}, id: 3},
      {name: "AED 2000 - 5000", value: {minPrice: 2000, maxPrice: 5000}, id: 4},
      {name: "AED 5000 and above", value: {minPrice: 5000, maxPrice: 999999}, id: 5},
    ],
    SAR: [
      {name: "SAR 0 - 500", value: {minPrice: 1, maxPrice: 500}, id: 1},
      {name: "SAR 500 - 1000", value: {minPrice: 500, maxPrice: 1000}, id: 2},
      {name: "SAR 1000 - 2000", value: {minPrice: 1000, maxPrice: 2000}, id: 3},
      {name: "SAR 2000 - 5000", value: {minPrice: 2000, maxPrice: 5000}, id: 4},
      {name: "SAR 5000 and above", value: {minPrice: 5000, maxPrice: 999999}, id: 5},
    ],
    QAR: [
      {name: "QAR 0 - 500", value: {minPrice: 1, maxPrice: 500}, id: 1},
      {name: "QAR 500 - 1000", value: {minPrice: 500, maxPrice: 1000}, id: 2},
      {name: "QAR 1000 - 2000", value: {minPrice: 1000, maxPrice: 2000}, id: 3},
      {name: "QAR 2000 - 5000", value: {minPrice: 2000, maxPrice: 5000}, id: 4},
      {name: "QAR 5000 and above", value: {minPrice: 5000, maxPrice: 999999}, id: 5},
    ],
  }
};