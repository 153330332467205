import {configureStore} from '@reduxjs/toolkit';
import userSlice from "./slices/userSlice";
import layoutSlice from "./slices/layoutSlice";
import authSlice from "./slices/authSlice";
import homeOptionsSlice from "./slices/homeOptionsSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    homeOptions: homeOptionsSlice,
    layout: layoutSlice,
  },
});

window.store = store; //test
